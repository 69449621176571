import React from 'react';
import { graphql } from 'gatsby'
import loadable from '@loadable/component'

/* Layouts */
import BaseLayout from 'layouts/base.layout'

/* Components */
import SEO from "components/seo"
import LoadingIndicator from 'components/loading-indicator'

import StageComponent from 'components/stage'
import ImageTextComponent from 'components/image-text'

const BenefitsComponent = loadable(() => import('components/benefits'))

const AboutPage = (props) => {
  return (
    <BaseLayout>
      <SEO title="Who we are" url={ props.location.href } />
      <StageComponent props={ props.data.stage } />
      <ImageTextComponent props={ props.data.imageText } />
      <div className="bg-white">
        <BenefitsComponent fallback={<LoadingIndicator />} props={ props.data.benefits }/>
      </div>
    </BaseLayout>
  )
}

export default AboutPage

export const query = graphql`
  query {
    stage: contentfulStage(page: {eq: "Wer wir sind"}) {
      title
      subtitle
    }

    imageText: allContentfulImageTextModule (
      filter: {page: {eq: "Wer wir sind"}}
    ) {
      ...imageTextQuery
    }

    benefits: allContentfulBenefitsModule (
      filter: {page: {eq: "Wer wir sind"}}
    ) {
      ...benefitsQuery
    }
  }
`